const updateToken = (type, tokenValue, expiration) => {
	return {
	  type: "UPDATE_TOKEN",
	  token: tokenValue,
	  expiration: expiration
	};
  };
  
  export default {
	updateToken
  };