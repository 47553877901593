import * as React from 'react';

import {
	Admin, Resource,
	List, Datagrid, SingleFieldList,
	ArrayField, ChipField, UrlField, EmailField, RichTextField, TextField, Edit,
	SimpleForm, EditButton,
	SelectInput, TextInput,
} from 'react-admin';

import { JwtManager } from './auth/JwtManager';
import { RichTextInput } from 'ra-input-rich-text';
import { dataProvider } from "./dataProvider";
import authProvider from './authProvider';
import MyLoginPage from './MyLoginPage';

const App = () => {
	return(
	<div> 
		<JwtManager/>
		<Admin loginPage={MyLoginPage} authProvider={authProvider} dataProvider={dataProvider}>
			<Resource name="Users" list={(props) => {
				return (
					<List {...props}>
						<Datagrid>
							<TextField source="field_330680" label="Name" />
							<TextField source="field_330820" label="Username.name" />
							<EmailField source="field_330821" label="email" />
							<UrlField source="field_330823" label="images" />
							<TextField source="field_344193" label="status" />
							<ArrayField source="field_346152" label="projects" reference="Projects" >
								<SingleFieldList>
									<ChipField source="value" />
								</SingleFieldList>
							</ArrayField>
							<ArrayField source="field_346203" label="projects_names">
								<SingleFieldList linkType="none">
									<TextField source="value" />
								</SingleFieldList>
							</ArrayField>
							<EditButton /> </Datagrid>	</List>)
			}} edit={(props) => {
				return (
					<Edit {...props}>
						<SimpleForm>
							<TextInput source="field_330680" label="Name" />
							<TextInput source="field_330820" label="Username.name" />
							<TextInput source="field_330821" label="email" />
							<TextInput source="field_330823" label="images" />
							<SelectInput source="field_344193" label="status" choices={[{ id: 87302, name: 'starting' }, { id: 87303, name: 'ongoing' }, { id: 87304, name: 'completed' }, { id: 87305, name: 'overdue' },]} />
							<TextField source="field_346152" label="projects" />																									      
							<TextInput source="field_346203" label="projects_names" />
							<ArrayField source="field_346203" label="projects_names">
								<SingleFieldList>
									<ChipField source="value" />
								</SingleFieldList>
							</ArrayField>
						</SimpleForm>
					</Edit>)
			}} />
			<Resource name="Posts" list={(props) => {
				return (
					<List {...props}>
						<Datagrid>
							<TextField source="field_334887" label="Name" />
							<RichTextField source="field_334890" label="Body" stripTags />
							<TextField source="field_336982" label="tel." />
							<EditButton /> </Datagrid>	</List>)
			}} edit={(props) => {
				return (
					<Edit {...props}>
						<SimpleForm>
							<TextInput source="field_334887" label="Name" />
							<RichTextInput source="field_334890" label="Body" stripTags />
							<TextInput source="field_336982" label="tel." />
						</SimpleForm>
					</Edit>)
			}} />
			<Resource name="Profiles" list={(props) => {
				return (
					<List {...props}>
						<Datagrid>
							<TextField source="field_340873" label="Username" />
							<TextField source="field_340876" label="Password" />
							<EditButton /> </Datagrid>	</List>)
			}} edit={(props) => {
				return (
					<Edit {...props}>
						<SimpleForm>
							<TextInput source="field_340873" label="Username" />
							<TextInput source="field_340876" label="Password" />
						</SimpleForm>
					</Edit>)
			}} />
			<Resource name="Projects" list={(props) => {
				return (
					<List {...props}>
						<Datagrid>
							<TextField source="field_344194" label="Name" />
							<TextField source="field_344195" label="Status" />
							<TextField source="field_344200" label="Tasks" />
							<TextField source="field_346153" label="Users" />
							<EditButton /> </Datagrid>	</List>)
			}} edit={(props) => {
				return (
					<Edit {...props}>
						<SimpleForm>
							<TextInput source="field_344194" label="Name" />
							<SelectInput source="field_344195" label="Status" choices={[{ id: 87192, name: 'Ongoing' }, { id: 87193, name: 'Starting' }, { id: 87194, name: 'Completed' },]} />
							<TextField source="field_344200" label="Tasks" />
							<TextField source="field_346153" label="Users" />
						</SimpleForm>
					</Edit>)
			}} />
			<Resource name="Tasks" list={(props) => {
				return (
					<List {...props}>
						<Datagrid>
							<TextField source="field_344197" label="Name" />
							<TextField source="field_344198" label="Project" />
							<EditButton /> </Datagrid>	</List>)
			}} edit={(props) => {
				return (
					<Edit {...props}>
						<SimpleForm>
							<TextInput source="field_344197" label="Name" />
							<TextField source="field_344198" label="Project" />
						</SimpleForm>
					</Edit>)
			}} />
		</Admin>
	</div>)
}
export default App;