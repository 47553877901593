export const bearerTokenUpdater = async () => {
	const parameters = {
	  method: 'POST',
	  headers: {Accept: 'application/json', 'Content-Type': 'application/json'},
	  body: JSON.stringify({
		clientId: '21fe97dc-7a28-4f9a-b61e-5b98bba186e8',
		secret: '9d159930-df73-41d1-b044-c738594e7579'
	  })
	};
	let response;
	try {
		response = await fetch('https://api.frontegg.com/auth/vendor/', parameters)
		.then(response => response.json())

		return response;
	}catch(err){
		console.log(err)//TODO change this 
		return null;
	}
  }

  export const calculateExpiringJWTTime = (duration) => {
	return new Date().getTime() + duration *1000;
  }

  

