
let token; 

export const updateGlobalToken = (newToken) => {
  token = newToken;
}

const authProvider = {
  login: async ({ username, password }) => {
    //loginPost(username, password)
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'frontegg-vendor-host': 'https://app-d7jd0cp0rkf9.frontegg.com',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({email: username, password: password})
    };
    
    return fetch('https://api.frontegg.com/identity/resources/auth/v1/user', options)
      .then(response => {
        console.log(response)
        if (response.status < 200 || response.status >= 300) {
          return Promise.reject();
        } else {
          localStorage.setItem("username", username);
          return Promise.resolve();
        }
      })
      .catch(() => {
        throw new Error('Network error')
      });
    },
    logout: () => {
        localStorage.removeItem('username');
        return Promise.resolve();
    },
        checkAuth: () =>
        localStorage.getItem('username') ? Promise.resolve() : Promise.reject(),
    checkError:  (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () =>
        Promise.resolve({
            id: 'user',
            fullName: 'John Doe',
        }),
    getPermissions: () => Promise.resolve(''),
    getRoles: () => Promise.reject('Not implemented'),
};

export default authProvider;