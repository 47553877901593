export const defaultState = {
    currentTime: 0
}


const timeReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_NEW_TIME":
            return {...state,
				currentTime: action.newTime
			}
		default:
			return state;
    }
}

export default timeReducer;