export const defaultState = {
    currentToken: undefined,
	expiration: undefined
}


const bearerTokenReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "UPDATE_TOKEN":
			console.log("IN UPDATE TOKEN")
			console.log(action);
            return {...state,
				currentToken: action.token,
				expiration: action.expiration
			}
		default:
			return state;
    }
}

export default bearerTokenReducer;
