import axios from "axios";
		import _ from 'lodash'; 
		
		const apiKey = "CIGFBxviZqwUlt6dkrSi8zch3V136wH5";    // switch to environment variable in production 
		
		const baserowClient = axios.create({                             // creates the baserow API client
			baseURL: 'https://api.baserow.io/api/database/rows/table/',
			timeout: 3000,
			headers: {Authorization: 'Token '+apiKey, 'Content-Type':'application/json'},
		  });
		
		const tableIDs = {
			'Users' : '57146', 
			'Posts' : '57716', 
			'Profiles' : '58778', 
			'Projects' : '59206', 
			'Tasks' : '59207', 	
		}	
		

		const fieldTypes = _.merge({'id': 'number', 'order': 'text',}  ,{
				'field_330680'	:	'text', 
				'field_330820'	:	'text', 
				'field_330821'	:	'email', 
				'field_330823'	:	'url', 
				'field_344193'	:	'single_select', 
				'field_346152'	:	'link_row', 
				'field_346203'	:	'lookup',  }  ,{
				'field_334887'	:	'text', 
				'field_334890'	:	'long_text', 
				'field_336982'	:	'phone_number',  }  ,{
				'field_340873'	:	'text', 
				'field_340876'	:	'text',  }  ,{
				'field_344194'	:	'text', 
				'field_344195'	:	'single_select', 
				'field_344200'	:	'link_row', 
				'field_346153'	:	'link_row',  }  ,{
				'field_344197'	:	'text', 
				'field_344198'	:	'link_row',  } )

		
		function parseOne(row) {
			let obj = {};
			for (const key of Object.keys(row)) {
				let fieldType = fieldTypes[key];
				switch (fieldType) {
				//	case 'link_row': 
				//		obj[key] = row[key].map(obj => obj.id);
				//		break;
				/*	case 'lookup': 
						obj[key] = row[key].map(obj => obj.value);
				break;  */
					case 'single_select':
						obj[key] = row[key].value;
						break;
					case 'multiple_select':
						obj[key] = row[key].value;
						break;
					default:
						obj[key] = row[key];
						break;
				}
			}
			return obj
		}

		function parseMany(rows) {
			let arr = [];
			for (const row of rows) {
				arr.push(parseOne(row));
			}
			return arr
		}


		export const dataProvider = {
			getList: (resource,params) => { return baserowClient.get(tableIDs[resource]+'/', {params: params.filter }).then(response => {
				return {
					status: response.status,
					statusText : response.statusText,
					headers: response.headers,
					data: parseMany(response.data.results),
					total: response.data.results.length,
					}
				});
			},	
			getOne: (resource,params) => { return baserowClient.get(tableIDs[resource]+'/'+params.id+'/').then(response =>{
					return {
						status: response.status,
						headers: response.headers,
						data: parseOne(response.data),
					}
				});
			},
			getMany: (resource,params) => { return new Promise((resolve,reject) => {
				let rows = params.ids;
		
				Promise.all(rows.map(row => baserowClient.get(tableIDs[resource]+'/'+row+'/'))).then(responses =>{
					return {
						data: parseMany(responses.map(response => response.data)),
					}
				});
			});	
			},
			create: (resource,params) => { return baserowClient.post(tableIDs[resource], params.data).then(response =>{
					return {
						status: response.status,
						headers: response.headers,
						data: response.data,
					}
				});
			},  
			update: (resource,params) => { return baserowClient.patch(tableIDs[resource]+'/'+params.id+'/', params.data).then(response =>{
				return {
					   status: response.status,
					   headers: response.headers,
					   data: response.data,
				   }
			   });
			},
			updateMany: (resource,params) =>  {  return new Promise((resolve,reject) => {
				let rows = params.ids;
				
				Promise.all(rows.map(row => baserowClient.patch(tableIDs[resource]+'/'+row+'/', params.data).then(responses =>{
					return {
						data: responses.map(response => response.data),
					}
				})));
			})       
			},
			delete: (resource,params) => { return baserowClient.delete(tableIDs[resource]+'/'+params.id+'/').then(response =>{
				return {
					status: response.status,
					headers: response.headers,
					data: response.data,
				}
			});
			},
			deleteMany: (resource,params) => { return new Promise((resolve, reject) => {
				let rows = params.ids;
		
				Promise.all(rows.map(row => baserowClient.delete(tableIDs[resource]+'/'+row+'/'))).then(responses =>{
					return {
						data: responses.map(response => response.data),
					}
				});
			});
			}
		}