import React, { useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux'
import {updateGlobalToken} from '../authProvider';
import {bearerTokenUpdater, calculateExpiringJWTTime} from './frontEggAuth'
import allActions from '../store/actions/allActions'


export const JwtManager = () => {
	
	const {currentToken, expiration} = useSelector(state => state.bearerTokenReducer)
	const {currentTime} = useSelector(state => state.timeReducer)
	const dispatch = useDispatch();


	useEffect(() => {
		dispatchCurrentTime();
	},[])

	useEffect(() => {
		if(!currentToken || !expiration || currentTime > expiration) {
			bearerTokenUpdater()
			.then(response => {
				const expirationTime = calculateExpiringJWTTime(response.expiresIn)
				dispatch(allActions.bearerTokenActions.updateToken("UPDATE_TOKEN", response.token, expirationTime))
				updateGlobalToken(response.token)
			});
		}
	},[currentTime])

	const dispatchCurrentTime= () =>{
		setInterval(() => {
			let currentTimeAsTimestamp = new Date().getTime() + 6*60000;
			dispatch(allActions.timeActions.setNewTime("SET_NEW_TIME", currentTimeAsTimestamp))
		}, 300000)
	}
	
	return(
		<div></div>
	)
}